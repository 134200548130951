/** @jsx jsx */
import React, { Suspense } from "react"

import { ClientOnly, Spinner } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const AppPage = React.lazy(() => import("../app"))

const Index = () => (
  <ClientOnly>
    <Suspense fallback={<Spinner isFullPage />}>
      <AppPage />
    </Suspense>
  </ClientOnly>
)

export default Index
